import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"sections",
        "id":"section_id",
        "extraoptions":[
            {
                "name":"List",
                "page":"/points",
            },
            {
                "name":"Map",
                "page":"/pointsmap",
            },
            {
                "name":"Stats",
                "page":"/sectionstats",
            },
        ],
        "subidname":"section_name",
        "options":["edit","insert","delete","listoptions"],
        "listoptions":[
            {
                "name":"Map",
                "link":"/pointsmap/?subid=[ids]&subname=Multiple sections&page=0",
            }
        ],
        "nameSingle":"section",
        "nameMultiple":"sections",
        "filters":
            [
                {
                    "key":"country",
                    "name":"Country",
                    "options":[
                        {key:"",value:"All"},
                        {key:"nl",value:"Nederland"},
                        {key:"be",value:"België"},
                        {key:"lu",value:"Luxemburg"},
                        {key:"de",value:"Duitsland"},
                        {key:"fr",value:"Frankrijk"},
                        {key:"sa",value:"Zuid-Afrika"}
                    ]
                }
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"section_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Road",
                "field":"section_road",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field3":{
                "name":"State",
                "field":"section_state",
                "type":"DropDown",
                "options": {
                    "0":"Inactive",
                    "1":"Active",
                },
                "required":true,
                "list":true

            },  
            "field4":{
                "name":"Kids",
                "field":"section_testing_kids",
                "type":"DropDown",
                "options": {
                    "1":"Testing",
                    "0":"Live",
                },
                "required":true,
                "list":true

            },  
            "field5":{
                "name":"Adult",
                "field":"section_testing_adult",
                "type":"DropDown",
                "options": {
                    "1":"Testing",
                    "0":"Live",
                },
                "required":true,
                "list":true

            },  
            "field6":{
                "name":"Land",
                "field":"section_country",
                "type":"DropDown",
                "options": {
                    "an":"Ander land",
                    "nl":"Nederland",
                    "be":"België",
                    "lu":"Luxemburg",
                    "de":"Duitsland",
                    "fr":"Frankrijk",
                    "sa":"Zuid-afrika", 
                },
                "required":true,
                "list":true
            },  
        }
    }
    
}